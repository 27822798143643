import { useStaticQuery, graphql } from 'gatsby';

interface SocialAccount {
  service: string;
  title: string;
  url: string;
}

const useSocial = (): SocialAccount[] => {
  const data = useStaticQuery(graphql`
    query {
      sanitySocialAccounts {
        accounts {
          service
          title
          url
        }
      }
    }
  `);

  return data.sanitySocialAccounts.accounts;
};

export default useSocial;
