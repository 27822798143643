/* eslint no-unused-vars: 0 */
import React from 'react';
import tw, { styled } from 'twin.macro';
import {
  FaGoogle,
  FaInstagram,
  FaYelp,
  FaFacebook,
  FaYoutube,
  FaTwitter,
} from 'react-icons/fa';

import useSocial from '../hooks/use-social';

type displayDir = {
  row: boolean;
};

const SocialContainer = styled.div<displayDir>`
  ${tw`flex flex-col`}
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
`;
const SocialLinkItem = styled.div`
  ${tw`pb-0 text-lg pr-4 pb-4 flex flex-row`}
`;
const IconWrapper = styled.span<displayDir>`
  ${tw`underline`};
  position: ${(props) => (props.row ? 'block' : 'relative')};
  padding-right: ${(props) => (props.row ? '0' : '1rem')};
  top: 6px;
`;
const SocialLabel = styled.span``;
const SocialLink = styled.a`
  ${tw`underline block`};
`;

type SocialAccountProps = {
  link: string;
  service: string;
  title?: string;
  showTitle: boolean;
  row: boolean;
};

const SocialAccount = ({
  link,
  service,
  title,
  showTitle,
  row,
}: SocialAccountProps) => {
  if (showTitle) {
    return (
      <SocialLinkItem>
        <IconWrapper row={row}>
          {service && service === 'instagram' ? <FaInstagram /> : null}
          {service && service === 'google' ? <FaGoogle /> : null}
          {service && service === 'facebook' ? <FaFacebook /> : null}
          {service && service === 'yelp' ? <FaYelp /> : null}
          {service && service === 'youtube' ? <FaYoutube /> : null}
          {service && service === 'twitter' ? <FaTwitter /> : null}
        </IconWrapper>
        <SocialLink href={link}>
          {title && <SocialLabel>{title}</SocialLabel>}
        </SocialLink>
      </SocialLinkItem>
    );
  }
  return (
    <SocialLinkItem>
      <SocialLink href={link}>
        <IconWrapper row>
          {service && service === 'instagram' ? <FaInstagram /> : null}
          {service && service === 'google' ? <FaGoogle /> : null}
          {service && service === 'facebook' ? <FaFacebook /> : null}
          {service && service === 'yelp' ? <FaYelp /> : null}
          {service && service === 'youtube' ? <FaYoutube /> : null}
          {service && service === 'twitter' ? <FaTwitter /> : null}
        </IconWrapper>
      </SocialLink>
    </SocialLinkItem>
  );
};

type SocialProps = {
  showTitle: boolean;
  row: boolean;
};

const Social = ({ showTitle = true, row = true }: SocialProps) => {
  const social = useSocial();

  return (
    <SocialContainer row={row}>
      {social.map((a, k) => (
        <SocialAccount
          link={a.url}
          title={a.title}
          service={a.service}
          showTitle={showTitle}
          key={k}
          row={row}
        />
      ))}
    </SocialContainer>
  );
};

export default Social;
