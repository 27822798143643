import React from 'react';
import tw, { styled } from 'twin.macro';

import Affiliations from './affiliations';
import AddressCard from './address-card';
import Social from './social';
import ScrollTop from './scroll-top';

const FooterWrapper = styled.footer`
  ${tw`md:flex p-4 pb-8 text-brown-350`};
  background-color: hsla(27, 12%, 10%, 1);
`;
const FooterItem = styled.div`
  ${tw`flex-none md:flex-1 pr-8 pb-8`};
`;
const FooterTitle = styled.h2`
  ${tw`uppercase font-light text-xl mb-6`};
  color: rgba(181, 161, 145, 1);
`;

const Footer = () => (
  <FooterWrapper>
    <FooterItem>
      <FooterTitle>Find Us</FooterTitle>
      <AddressCard />
      <Social showTitle row={false} />
    </FooterItem>
    <FooterItem>
      <FooterTitle />
    </FooterItem>
    <FooterItem>
      <FooterTitle>Affiliations</FooterTitle>
      <Affiliations />
    </FooterItem>
    <ScrollTop />
  </FooterWrapper>
);

export default Footer;
