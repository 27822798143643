import React from 'react';
import tw, { styled } from 'twin.macro';

import useAffiliations from '../hooks/use-affiliations';
import useCompanyInfo from '../hooks/use-company-info';

const AffiliateContainer = styled.div`
  ${tw`pr-0 text-base`}
`;

const AffiliateLinks = styled.ul`
  ${tw`list-none pl-0 pt-4`};
`;

const LinkItem = styled.a`
  ${tw`block underline font-medium text-base pb-4`};
`;

const Callout = styled.span`
  ${tw`font-medium text-lg`};
  color: rgba(181, 161, 145, 1);
`;

type AffiliateLinkProps = {
  url: string;
  title: string;
};

const AffiliateLink = ({ url, title }: AffiliateLinkProps) => (
  <li>
    <LinkItem title={title} href={url}>
      {title}
    </LinkItem>
  </li>
);

const Affiliations = () => {
  const { name } = useCompanyInfo();
  const affiliates = useAffiliations();

  return (
    <AffiliateContainer>
      <p>
        <Callout>{name}</Callout> is a division of Frying Pan Anglers. Click the
        links below to visit our other properties.
      </p>
      <AffiliateLinks>
        {affiliates.map((a) => (
          <AffiliateLink
            url={a.affiliateUrl}
            title={a.affiliateName}
            key={a.affiliateUrl}
          />
        ))}
      </AffiliateLinks>
    </AffiliateContainer>
  );
};

export default Affiliations;
