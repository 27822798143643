import { useStaticQuery, graphql } from 'gatsby';

interface CompanyInfo {
  name: string;
  email: string;
  phoneNum: string;
  hours: string[];
  address: {
    address1: string;
    city: string;
    state: string;
    zipCode: number;
  };
  mapMeta: {
    mapText: string;
    mapUrl: string;
  };
}

const useCompanyInfo = (): CompanyInfo => {
  const data = useStaticQuery(graphql`
    query {
      sanityCompanyInfo {
        name
        email
        phoneNum
        hours
        address {
          address1
          address2
          city
          state
          zipCode
        }
        mapMeta {
          mapUrl
          mapText
        }
      }
    }
  `);

  return data.sanityCompanyInfo;
};

export default useCompanyInfo;
