import React, { ReactNode } from 'react';
import { Global } from '@emotion/react';
import tw, { styled, css, GlobalStyles } from 'twin.macro';

import Navigation from './navigation';
import Footer from './footer';
// import 'react-image-lightbox/style.css';

const PageTemplateWrapper = styled.div`
  ${tw`font-body font-light mx-auto max-w-screen-xl bg-primary-450`}
`;
const ContentWrapper = styled.div`
  ${tw`mt-0 text-base font-light bg-primary-100`}
`;

type TemplateProps = {
  children: ReactNode;
};

const PageTemplate = ({ children }: TemplateProps) => (
  <PageTemplateWrapper>
    <GlobalStyles />
    <Global
      styles={css`
        body {
          ${tw`bg-primary-450`}
        }
        p {
          ${tw`mb-4`}
        }
      `}
    />
    <Navigation />
    <ContentWrapper>{children}</ContentWrapper>
    <Footer />
  </PageTemplateWrapper>
);

export default PageTemplate;
