import React from 'react';
import tw, { styled } from 'twin.macro';
import { MdLocationOn, MdPhone, MdEmail } from 'react-icons/md';

import useCompanyInfo from '../hooks/use-company-info';

const CompanyName = styled.p`
  ${tw`mb-4 font-medium text-lg`};
`;
const AddressCardContainer = styled.div`
  ${tw`mt-4`}
`;
const Address = styled.ul`
  ${tw`list-none`}
`;
const AddressItem = styled.li`
  ${tw`pb-3 text-lg flex`}
`;
const IconWrapper = styled.span`
  ${tw`relative pr-4`}
  top: 4px;
`;
const LocationIconWrapper = styled.div`
  ${tw`relative pr-4`}
  top: 6px;
`;

type AddressCardProps = {
  name?: string;
  address?: {
    address1: string;
    city: string;
    state: string;
    zipCode: number;
  };
  phoneNum?: string;
  email?: string;
};

const AddressCard = () => {
  const { name, address, phoneNum, email }: AddressCardProps = useCompanyInfo();

  return (
    <AddressCardContainer>
      {name && <CompanyName>{name}</CompanyName>}
      <Address>
        {address && (
          <AddressItem>
            <LocationIconWrapper>
              <MdLocationOn />
            </LocationIconWrapper>
            <div>
              {address.address1} <br />
              {address.city}, {address.state} {address.zipCode}
            </div>
          </AddressItem>
        )}
        {phoneNum && (
          <AddressItem>
            <IconWrapper>
              <MdPhone />
            </IconWrapper>
            <span>{phoneNum}</span>
          </AddressItem>
        )}
        {email && (
          <AddressItem>
            <IconWrapper>
              <MdEmail />
            </IconWrapper>
            <span>
              <a href={`mailto:${email}`}>{email}</a>
            </span>
          </AddressItem>
        )}
      </Address>
    </AddressCardContainer>
  );
};

export default AddressCard;
