import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';

import Social from './social';
import logo from '../images/gsologo.png';

const Logo = styled.img`
  ${tw`block md:mt-1 md:mb-2`}
  height: 100px;
`;

type NavLinkProps = {
  to: string;
  label: string;
};

const NavLink = ({ to, label }: NavLinkProps) => (
  <Link
    to={to}
    className="navbar-item is-tab"
    activeClassName="is-active"
    tw="uppercase"
  >
    {label}
  </Link>
);

const Navigation = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolling = window.scrollY !== 0;
      setIsScrolling(scrolling);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    const navBurger = document.querySelector<HTMLElement>('.navbar-burger');
    if (!navBurger) return;

    const target = navBurger.dataset.target || 'navMenu';
    const navMenu = document.getElementById(target);
    const handleClick = () => {
      navBurger.classList.toggle('is-active');
      if (navMenu) {
        navMenu.classList.toggle('is-active');
      }
    };

    navBurger.addEventListener('click', handleClick, { passive: true });

    return () => navBurger.removeEventListener('click', handleClick);
  });

  return (
    <nav
      className={`navbar is-primary top-navbar is-fixed-top ${
        isScrolling ? 'scrolling' : ''
      }`}
      role="navigation"
      aria-label="main navigation"
      tw="bg-primary-400 text-xl uppercase py-2 md:py-8 font-heading"
    >
      <div className="navbar-brand">
        <Link to="/" className="navbar-item" title="Logo">
          <Logo src={logo} alt="logo of black trees on white background" />
        </Link>
        <div className="navbar-burger burger" data-target="navMenu">
          <span />
          <span />
          <span />
        </div>
      </div>
      <div id="navMenu" className="navbar-menu">
        <div className="navbar-start">
          <NavLink to="/" label="Home" />
          <NavLink to="/guided-trips/" label="Guided Trips" />
          <NavLink to="/retail-store/" label="Retail Store" />
          <NavLink to="/archery/" label="Archery" />
        </div>
        <div className="navbar-end" tw="hidden pr-2 md:pr-6 md:inline-block">
          <Social showTitle={false} row />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
