import { useStaticQuery, graphql } from 'gatsby';

interface Affiliate {
  affiliateName: string;
  affiliateUrl: string;
}

const useAffiliations = (): Affiliate[] => {
  const data = useStaticQuery(graphql`
    query {
      sanityAffiliations {
        affiliates {
          affiliateName
          affiliateUrl
        }
      }
    }
  `);

  return data.sanityAffiliations.affiliates;
};

export default useAffiliations;
