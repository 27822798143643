import { useStaticQuery, graphql } from 'gatsby';

interface SiteSettings {
  title: string;
  description: string;
  author: string;
}

const useSiteMetadata = (): SiteSettings => {
  const data = useStaticQuery(
    graphql`
      query SeoQuery {
        sanitySiteSettings {
          title
          description
          author
        }
      }
    `
  );

  return data.sanitySiteSettings;
};

export default useSiteMetadata;
